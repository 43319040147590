.section5-list-hot-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 15px;
    max-width: 90%;

    &__title {
          box-sizing: border-box;
            background-color: rgb(235 231 231);
            height: 30px;
            width: 90%;
        &-link {
            line-height: 24px;
            text-align: center;
            overflow: hidden;
            margin-bottom: 15px;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
            display: inline-block;
            position: relative;
            font-size: 18px;
            font-weight: 200;
            line-height: 36px;
            text-transform: uppercase;
            color: brown;
            vertical-align: middle;
            text-decoration: none;
          

            h3:before {
                right: 100%;
                margin-right: 15px;
                content: "";
                position: absolute;
                height: 1px;
                width: 1000px;
                border-top: 1px dashed #15a69d;
                top: 18px;
            }

            h3:after {
                left: 100%;
                margin-left: 15px;
                content: "";
                position: absolute;
                height: 1px;
                width: 1000px;
                border-top: 1px dashed #15a69d;
                top: 18px;
            }
        }

    }

    &__product-hot-grp {}

    &__list-product {}

    &__product:hover {
        transition: all 0.3s ease;
        transform: scale(1.15);
    }

    &__product {
        overflow: hidden;
        position: relative;
        padding: 10px 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        img {
                height: 240px;
                max-width: 290px;
                width: 100%;
                object-fit: cover;
            }

        &__quickview {
            display: none;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -50px;
            width: 100%;
            background-color: rgba(21, 166, 157, .6);
            text-align: center;
            display: block;
            padding: 5px;
            transition: all .45s ease;
            line-height: 28px;
        }

        p {
            left: 0;
            right: 0;
            bottom: 40px;
            width: 100%;
            font-size: 18px;
            font-weight: 200;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
            text-align: center;
            padding: 0 15px;
            text-transform: uppercase;
            line-height: 24px;
            transition: all .45s ease;
            color: brown;
        }
    }

    &__product:hover &__product__quickview {
        display: block;
    }

    &__product-name {
        font-size: 15px;
        font-weight: 200;
    }

    &__product-price {
        font-size: 16px;
        font-weight: 600;
    }

    &__btn-shopping {
        button {
            width: 160px;
            height: 42px;
            border: 1px solid black;
            border-radius: 0;
            background-color: #f7eee8;
        }

        button:hover {
            border: 1px solid black !important;
            color: black !important;
            background-color: #fcefe7;
            transition: all 0.3s ease-in;
        }

        span {
            position: absolute;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 200;
        }
    }
}