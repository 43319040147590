.profile-nav-wrapper {
  width: 300px;
  margin-top: -70px;
}

.profile-nav {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__avatar-grp {
    img {
      max-width: 200px;
      height: 200px;
      border-radius: 50%;
    }

    &--user-name {
      font-size: 20px;
      font-weight: 400;
    }
  }

  &__list-information-grp {}

  &__box-password {
    display: none;
  }

  &__show-box {
    display: block;
  }

  &__list-information {
    list-style: none;

    li {
      cursor: pointer;
      padding: 10px 0;
      font-size: 18px;
      font-weight: 500;
    }

    li:first-child {
      margin-top: 10px;
    }

    li:hover {
      color: rgb(179, 126, 107);
      text-decoration: underline;
      transform: scale(1.05);
      transition: all 0.3s ease;
    }
  }
}

.change-pass-word-form {
  display: flex;
  flex-direction: column;
  gap: 3px;

  &__btn-save {
    display: flex;
    gap: 5px;
    justify-content: center;
  }

}

@media screen and (max-width: 1132px) {}