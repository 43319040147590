.section4-wrapper {
    background-color: brown;
    margin: 0 10px;
        padding: 0 15px;
        h3 {
            color: white !important;
            padding: 10px !important;
    font-family: math;

        }
    .section4-container {
 .section4-list-container {

 }
    }

    img {
        border-radius: 3px;
        margin: 0 10px;
        padding: 0 15px;
        width: 100%;
        max-width: 450px;
    }
}