.slider-other-container {
    position: relative;

    .custom-prev,
    .custom-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        border: none;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1;
    }

    .custom-prev {
        left: 5px;
    }

    .custom-next {
        right: 5px;
    }
    
    .centered-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}
