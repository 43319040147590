* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.section2-wrapper {
  display: flex;
  justify-content: center;
  background-color: mintcream;
  margin-top: 20px;
}

.section2-container {
  display: flex;
  align-items: center;
  width: 90vw;
  max-width: 1200px;
}

.section2-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-right: 20px;

  p {
    margin: 20px 20px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 600;
  }

  &__description {
    display: flex;
    justify-content: center;
    text-align: justify;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 400;
  }

  &__btn-show {
    display: flex;
    justify-content: center;

    button {
      width: 136.5px;
      height: 40px;
      border: 1px solid black;
      border-radius: 30;
      background-color: #f7eee8;

      span {
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    button:hover {
      background-color: #fff;
      border: 1px solid black !important;
      transition: all 0.3s ease;
      span {
        color: #2f251e;
      }
    }
  }
}

.section2-right {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;

  img {
    max-width: 590px;
    width: 45vw;
    object-fit: cover;
  }
}

@media screen and (max-width: 768px) {
  .section2-right {
    padding-top: 20px;
    img {
      width: 100% !important;
    }
  }
}
