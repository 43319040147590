.footer-wrapper {
  display: flex;
  justify-content: center;
  border-top: 1px solid black;
  background-color: brown;
  color: snow;

  .phone_mobi {
    background-color: brown;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    line-height: 18px;
    color: #fff;
    z-index: 50;
    padding: 6px 0;
    box-shadow: 0px 0px 3px #000;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
    }

    li {
      flex: auto 1 0;
      color: #fff;
      text-align: center;
    }

    a {
      color: #fff;
    }

    g {
      mix-blend-mode: normal
    }
  }
}

.footer-container {
  width: 90vw;
  padding: 30px;
}

.footer-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 10px;

  &__title {
    h3 {
      font-size: 22px;
      font-weight: 500;
    }
  }

  &__description-grp>p>a {
    color: snow;
    text-decoration: none;
  }

  &__description {
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: left;
  }



  &__bottom {
    display: flex;
    align-items: center;
    gap: 20px;

    svg {
      font-size: 20px;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 600px) {
  .mobile_none {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .footer-item {
    padding-left: 20px;
  }
  .footer-item {
    flex-direction: column;
  }
  .footer-item_social {
    iframe {
      max-width: 100%;
    }
  }
}