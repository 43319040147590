* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-layout-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
overflow-x: hidden;
  &__content {
      margin: 0 auto;
    font-family: "Muli", "Roboto Condensed", "sans-serif";
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &__header &__footer {
    width: 100%;
  }
}
.main-layout-container__content {
  margin-top: 170px;
h3 {
  color: brown;
  text-transform: uppercase;
  font-size: 22px;
    line-height: 36px;
}
}
@media (max-width: 600px) {
  .main-layout-container__content {
    margin-top: 60px;
  }
  }
  @media (max-width: 480px) {
      
  }