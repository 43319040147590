// * {
//   box-sizing: border-box;
// }

.detail-product-card-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
p {
  font-size: 14px;
}
.ant-image-mask {
  display: none;
}
.ant-image-img {
  cursor: zoom-in;
}
.detail-product-card-container {
  margin: 0 auto;
  max-width: 80vw;
}
  .detail-product-card-top-container,
  .detail-product-card-top-wrapper,
  .detail-product-card-bottom-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 ảnh trên máy tính */
    gap: 10px;
    justify-items: center;
     img {
      width: 100%;
      height: auto;
    }
}
.single-image {
   display: grid;
        grid-template-columns: 1fr; /* 1 ảnh trên điện thoại */
    gap: 10px;
    justify-items: center;
    img {
      width: 30vw;
    }
}

.grid-image {
    width: 100%;
}

/* Media query cho điện thoại (một ảnh mỗi hàng) */
@media (max-width: 768px) {
    .image-grid {
        grid-template-columns: 1fr; /* 1 ảnh trên điện thoại */
    }
    .single-image {
   display: grid;
        grid-template-columns: 1fr; /* 1 ảnh trên điện thoại */
    gap: 10px;
    justify-items: center;
    img {
      width: 100%;
      height: auto;
    }
}
}


.image-slider {
  max-width: 100vw;
  text-align: center;
}
  .detail-product-card-top {
    max-width: 100%;
    width: 100%;

    &__img-grp,
    &__information-product-grp {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__large-img img {
      max-width: 600px;
      width: 100%;
      object-fit: cover;
    }

    &__img-small-grp {
      padding-top: 20px;
      display: flex;
      justify-content: center;
      gap: 10px;
      cursor: pointer;

      img {
        width: 100px;
        height: 100px;
      }
    }

    &__product-name h3 {
      font-size: 22px;
      font-weight: 400;
      padding: 20px 0;
    }

    &__product-price h3 {
      color: black;
      font-size: 22px;
      font-weight: 600;
      padding-bottom: 20px;
    }

    &__product-quantity-grp {
      width: 80%;
      border-top: 1px solid rgb(213, 213, 213);
      border-bottom: 1px solid rgb(213, 213, 213);
    }
   &__shipping-warranty-grp {
      padding: 0 20px;
    }
    &__btn-add-cart {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
   
      button {
        width: 220px;
        height: 50px;
        font-size: 20px;
        color: white;
        background-color: #15a69d;
      }

      button:hover {
        color: orange !important;
        background-color: #127f79;
      }
    }
  }

  .detail-product-card-describe-wrapper {
    font-family: "Muli", "Roboto Condensed", "sans-serif";
    font-size: 17px;

    h3,
    h5 {
      color: #15a69d;
    }
  }

  .detail-product-card-bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;

    &__related-products-grp-item {
      padding: 20px 10px;

      img {
        max-width: 300px;
        width: 100%;
        cursor: pointer;
      }

      &--name {
        color: rgb(179, 126, 107);
        cursor: pointer;
        font-size: 17px;
      }

      &--price {
        font-weight: 600;
        font-size: 17px;
      }
    }
  }
}

.detail-product-card-comment {
  border: 1px solid rgb(240, 240, 240);
  background-color: #f9f9f9;
  width: 90%;
  max-width: 1360px;
  margin: 10px 10px 50px;

  &__name-product {
    border-bottom: 1px solid rgb(210, 210, 210);
    padding: 20px;
    display: flex;
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__star-average {
    border-bottom: 1px solid rgb(210, 210, 210);
    padding: 10px;
    display: flex;
    text-align: center;
    justify-content: space-evenly;

    &--title-review {
      padding-bottom: 10px;
      p {
        font-size: 16px;
        font-weight: 500;
      }
    }

    &--star-avg {
      padding-bottom: 10px;
      svg {
        font-size: 30px;
      }
    }

    &--grp-btn-open-review-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &--question {
      padding-bottom: 10px;
      max-width: 200px;
    }

    &--btn {
      max-width: 200px;
      button {
        font-size: 16px;
        height: 40px;
        background-color: #e2e2e2;
      }

      button:hover {
        color: white !important;
        background-color: #b37e6b;
      }
    }
  }

  &__box-review-wrapper {
    display: none;
  }

  &__show-box {
    display: flex;
    justify-content: center;
  }

  &__box-review {
    min-width: 500px;
    max-width: 650px;
    border: 1px solid rgb(208, 208, 208);
    border-radius: 5px;
    margin: 15px;
    background-color: #e7e7e7;

    &--img-product {
      display: flex;
      justify-content: center;
      padding: 10px;
      img {
        border-radius: 10px;
        width: 150px;
        height: 150px;
      }
    }

    &--name-product {
      p {
        font-weight: 500;
      }
    }

    &--your-rate {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
      svg {
        font-size: 24px;
      }
    }

    &--comment {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
      input {
        max-width: 350px;
      }
    }

    &--btn-done {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
      button {
        font-size: 16px;
        height: 40px;
        border: 1px solid rgb(191, 191, 191);
        background-color: #e2e2e2;
      }
      button:hover {
        color: white !important;
        background-color: #b37e6b;
      }
    }
  }

  &__filter-star {
    background-color: #ffffff;
    display: flex;
    padding: 10px;

    &--title {
      margin-left: 10px;
      p {
        font-size: 16px;
        font-weight: 500;
      }
    }

    &--item-grp {
      display: flex;
      align-items: center;
      margin: 0 30px;
    }

    &--item {
      border: 1px solid black;
      margin: 0 10px;
      width: 50px;
      display: flex;
      justify-content: center;
      border-radius: 5px;
      padding: 2px 5px;
      cursor: pointer;
    }

    &--item:hover {
      background-color: #eaeaea;
    }
  }

  &__review {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgb(210, 210, 210);
    padding: 10px;

    &--item {
      display: flex;
    }

    &--avatar-user {
      padding: 10px 0px 10px 30px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
      }
    }

    &--grp-right {
      width: 100%;
      padding-left: 30px;
    }

    &--user-name {
      display: flex;
      p {
        font-size: 18px;
        font-weight: 500;
      }
    }
    &--star {
      display: flex;
      svg {
        font-size: 20px;
      }
    }
    &--comment {
      display: flex;
      p {
        font-size: 18px;
        font-weight: 400;
        color: #505050;
      }
    }

    &--date-comment {
      display: flex;
      p {
        font-size: 14px;
        font-weight: 500;
        color: #a5a5a5;
      }
    }
  }

  &__reviews-pagination {
    padding: 10px;
    display: flex;
    justify-content: center;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .detail-product-card-wrapper {
    flex-direction: column;
    padding: 0 16px;
  }

  .detail-product-card-top {
    width: 100%;
    flex-direction: column;
    gap: 16px;
  }

  .detail-product-card-top__btn-add-cart button {
    width: 100%;
  }

  .detail-product-card-comment {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .detail-product-card-top {
    width: 85%;
  }
}

@media (max-width: 400px) {
  .detail-product-card-top__img-small-grp img {
    width: 80px;
    height: 80px;
  }

  .detail-product-card-top__product-name h3 {
    font-size: 24px;
  }
}
