.product-card-wrapper {
  display: flex;
  gap: 20px; /* Khoảng cách giữa các thẻ sản phẩm */
  flex-wrap: wrap;
  justify-content: center; /* Căn giữa các thẻ sản phẩm */
}

.product-card {
  width: 300px; /* Đặt chiều rộng cố định cho thẻ sản phẩm */
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  border-radius: 8px;
  transition: border-color 0.3s ease, transform 0.3s ease;
  overflow: hidden; /* Đảm bảo nội dung không tràn ra ngoài */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Thêm bóng cho thẻ */
}

.product-card:hover {
  border-color: #1890ff;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.product-card__img {
  position: relative;
  width: 100%;
  height: 250px; /* Đặt chiều cao cố định */
  overflow: hidden;
}

.product-card__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-card__infor {
  padding: 15px; /* Căn padding đồng đều */
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.product-card__name h4 {
  font-size: 16px;
  margin: 0;
  transition: color 0.3s ease;
}

.product-card__name:hover h4 {
  color: #b37e6b;
}

.product-card__price {
  margin-top: auto;
  font-size: 16px;
  font-weight: 500;
}

.button-shop {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.button-shop > button {
  background-color: #0167f3;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  text-transform: capitalize;
  border: none;
    cursor: pointer;

}

.product-card__img:hover .button-shop {
  opacity: 1;
}
