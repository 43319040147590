.App {
  font-family: math;
}

html {
  scroll-behavior: smooth;
}
.d-flex {
  display: flex;
}
.gap-3 {
  gap: 30px;
}
.gap-4 {
  gap: 40px;
}
.h-100 {
  height: 100%;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-between {
  justify-content: space-between;
}
.w-100 {
  width: 100%;
}
.flex-column {
  flex-direction: column;
}
@media screen and (max-width: 500px) {
  .mobile_none {
    display: none;
  }
}
