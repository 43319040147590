// Container chính của section
.section1-container {
  padding: 30px; // Thêm padding xung quanh container chính
  background-color: #f7f7f7; 
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

// Tiêu đề của section
.section1-container-title {
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px; // Padding dưới của tiêu đề để tạo khoảng cách
}

.section1-container-title-link {
  text-decoration: none;
  color: brown;
  font-weight: bold;
}

.section1-container-title-link h3 {
  font-size: 24px;
  margin: 0;
  padding: 0;
  color: brown;
  text-transform: uppercase;
}

// Danh sách sản phẩm
.section1-container__list-product {
  justify-content: space-between;
  padding: 10px 0; // Thêm padding trên và dưới cho danh sách sản phẩm
}

// Mỗi sản phẩm
.section1-container__product {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: calc(33.33% - 20px); // Mỗi sản phẩm chiếm 1/3 chiều rộng của container
  padding: 15px; // Padding xung quanh mỗi sản phẩm
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05); // Phóng to sản phẩm khi hover
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); // Thêm bóng khi hover
  }

  img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding-bottom: 15px; // Thêm padding dưới ảnh để tạo khoảng cách với tiêu đề
  }

  h4 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: brown;
    margin-top: 10px;
    padding: 0 10px; // Thêm padding ngang cho tiêu đề để không sát vào ảnh
    transition: all 0.45s ease;
  }
}

// Responsive cho các kích thước màn hình nhỏ hơn
@media (max-width: 1024px) {
  .section1-container__product {
    width: calc(50% - 20px); // Mỗi sản phẩm chiếm 1/2 chiều rộng của container
  }
}

@media (max-width: 768px) {
  .section1-container__product {
    width: 100%; // Mỗi sản phẩm chiếm toàn bộ chiều rộng
  }
}
