.header-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  //  background-image: url('https://denlongthiendang.com/den/logo/bia.png') ;
  height: 170px !important;
  background-color: #f8f6f4;
  position: fixed;
  z-index: 2000;
  .header-container-menu-bar {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-self: center;
  &__toggle-icon {
      display: none; 
    }
    &__logo {
      &__link {
        img {
          width: 300px;
          height: 75px;
        }
      }
    }

    &__search {
      width: 100%;
      align-self: end;
      .list-product__search {
        display: flex;
        gap: 5px;

        &-btn {
          background-color: brown;
        }
      }
    }

    &__account {
      display: flex;
      justify-content: center;
      gap: 15px;
      align-items: end;
      color: rgb(26, 25, 25);
      overflow: hidden;
      padding: 0 5px;
      cursor: pointer;

      svg {
        font-size: 40px;
      }

      p {
        align-content: end;
      }

      &-cart {
        &-shoppingCartCount {
          border: 1px solid #15a69d;
          border-radius: 100%;
          width: 25px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: brown;
          position: absolute;

          p {
            font-size: 14px;
            color: white;
          }
        }
      }
    }

    &__account-member {
      display: flex;
    }

    &__shoppingCartCount {
    }

    &__account-member {
    }
  }

  .header-container-nav {
    width: 100%;
    color: #403f41;
    font-family: "Muli", "Roboto Condensed", "sans-serif";
    font-weight: 600;
    font-size: 12px;
  }
  ////
  .header-container-nav-mobile {
    display: none;
  }
   @media (max-width: 600px) {
    .toogle-hiden {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    color: brown;
    /* padding: */
  }
    .header-container-menu-bar__toggle-icon {
      display: block; // Show the toggle icon on mobile
    }

    .header-container-nav-mobile {
      display: flex; // Show the mobile nav menu as a column layout
    }
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header-wrapper {
  display: flex;
  justify-content: center;
  background-color: #f8f6f4;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
}

.header-container {
  display: flex;
  justify-content: end;
  max-width: 100%;
  width: 100%;
}

.header-navBar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  &__logo {
    img {
      width: 150px;
      height: 28px;
      cursor: pointer;
      margin-right: 50px;
    }
  }

  &__list {
    list-style-type: none;
    text-transform: uppercase;
  }

  &__listItem {
    display: inline-block;
    padding: 30px 40px;
    text-align: center;
    cursor: pointer;

    a {
      position: relative;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    span {
      color: white;
    }

    a:hover {
      transition: all 0.3s ease;
      transform: scale(1.3);

      span {
        color: white;
      }
    }

    span.anticon {
      position: absolute;
      left: -20px;
      bottom: 3px;
      cursor: pointer;
      font-size: 10px;
    }
  }

  &__shopItem {
    position: relative;
  }

  &__subNavProduct {
    position: absolute;
    left: 10px;
    top: 60px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0.1px 0.1px 4px #888888;
    // loại bỏ thuộc tính hiển thị => mới dùng đc transition
    // vì display none => block ko phải là animation => k dùng đc
    visibility: hidden;
    opacity: 0;
    z-index: 100;

    // display: none;
    li {
      list-style: none;
      justify-content: left;
      padding: 10px;
      width: 220px;
      cursor: pointer;
    }
  }
  &__subNavProduct-item {
    span {
      color: black;
    }
  }

  &__subNavProduct-item:hover {
    transition: all 0.3s ease;
    // transform: scale(1.3);
    background-color: brown;

    span {
      color: white;
    }
  }

  &__shopItem:hover &__subNavProduct {
    // display: block;
    opacity: 1;
    visibility: visible;
    transition: opacity 1s, visibility 1s;
    -webkit-transition: opacity 600ms, visibility 600ms;
  }

  &__btnClosed-navBar {
    display: none;
  }
}

.header-left {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;

  &__searchBox {
    display: flex;
    gap: 16px;
    background-color: white;
    padding: 4px;
    border-radius: 25px;

    input {
      border-radius: 25px;
      border: none;
    }

    span {
      display: flex;
      align-items: center;

      svg {
        font-size: 20px;
        color: #b37e6b;
        cursor: pointer;
      }
    }
  }

  &__shoppingCart,
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    // border: 1px solid #b37e6b;
    border-radius: 25px;
    width: 70px;
    height: 40px;
    cursor: pointer;
  }

  &__shoppingCart {
    position: relative;
  }

  &__loginRegisterGrp {
  }

  &__user-grp {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 10px;
  }

  &__user-avatar {
    img {
      width: 30px;
      display: flex;
      cursor: pointer;
    }
  }
}

.header-btn-show-navBar {
  display: none;
}

@media screen and (max-width: 900px) {
  .header-container .header-container-menu-bar {
    align-items: center;
  }

  .header-navBar__list {
    flex-direction: column;

    .header-navBar__subNavProduct-item {
      text-align: center;
      span {
        color: black;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header-container {
    .header-container-top-header {
      display: none;
    }

    

    .header-container-menu-bar {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__logo {
        height: 70px;
      }
    }
  }

  .menu-bar {
    flex-direction: column;
    align-items: flex-start;

    &__account {
      .loginRegisterGrp,
      .user-grp {
        margin: 0;
        margin-top: 10px;
      }
    }
  }

  .nav-bar {
    .nav-bar__list {
      flex-direction: column;

      .nav-list {
        flex-direction: column;

        .nav-item {
          text-align: center;
        }
      }
    }

    .show {
      display: block;
    }
  }
  .header-container-nav-mobile {
    display: flex !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 75%;
    height: 100%;
    background-color: #2c343f;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
        padding-left: 20px;
    z-index: 1000;
    overflow-y: auto;

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
    }

    &__item {
      margin: 20px 0;
      font-size: 18px;
      &__sub {
        padding-left: 10px;
              margin: 16px 0;

      }

      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }

 
}

@media screen and (max-width: 600px) {
  .mobile_none {
    display: none;
  }
  .header-container {
    height: 80px !important;
    justify-content: center;
  }
  .header-container-menu-bar__logo {
    width: 100%;
  }
}
.ant-dropdown {
    z-index: 10000;
  }