.carousel-container {
  text-align: -webkit-center;
}

.carousel {
  &__item1,
  &__item2,
  &__item3,
  &__item4,
  &__item5 {
    max-height: 400px;
    width: 100%;
    max-width: 900px !important;
    object-fit: cover;
  }

  &__item1 {
    object-position: 25% 75%;
  }

  &__item2 {
    object-position: 50% 50%;
  }

  &__item3 {
    object-position: 25% 75%;
  }

  &__item1 {
    object-position: 25% 75%;
  }

  &__item1 {
    object-position: 25% 75%;
  }
}

@media screen and (max-width: 1148px) {
  .carousel-container {
    margin-top: 80px;
  }
}

@media screen and (max-width: 900px) {
  .carousel-container {
    margin-top: 0px;
  }
  .carousel {
    &__item1,
    &__item2,
    &__item3,
    &__item4,
    &__item5 {
      max-height: 300px;
      width: 100%;
      object-fit: cover;
    }
  }
}
@media (max-width: 1200px) {
  .carousel-container {
    padding: 0 15px;
  }

  .carousel .carousel__item {
    height: 250px; // Adjust as needed for smaller screens
  }
}

@media (max-width: 768px) {
  .carousel-container {
    padding: 0 10px;
    max-width: 90%;
  }

  .carousel .carousel__item {
    height: 200px; // Adjust as needed for smaller screens
  }
}

@media (max-width: 480px) {
  .carousel-container {
}

  .carousel .carousel__item {
    height: 150px; // Adjust as needed for smaller screens
  }
}