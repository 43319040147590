/* style.scss */

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;

  h3 {
    color: #d9534f;
    font-size: 18px;
  }

  img {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    display: block;
    border-radius: 10px;
  }

  p {
    font-size: 14px;
    color: #333;
    margin: 10px 0;
  }
}

/* Responsive styling */
@media (min-width: 768px) {
  .about-container {
    padding: 0 40px;
    p {
      font-size: 1.2rem;
    }

    h3 {
      font-size: 18px;
    }
  }
}

@media (max-width: 767px) {
  .about-container {
    padding: 10px;

    h3 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
}
