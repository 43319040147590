/* Giới hạn chiều rộng của toàn bộ trang và căn giữa nội dung */
.content-product-all-wrapper {
  max-width: 900px; /* Đặt giới hạn chiều rộng */
  margin: 0 auto; /* Căn giữa nội dung */
  padding: 20px; /* Thêm padding cho nội dung */
}

.content-product-all-container__breadcrumb {
  margin-bottom: 20px; /* Tạo khoảng cách phía dưới breadcrumb */
}

.content-product-all-container__description {
  text-align: center;
  margin-bottom: 20px; /* Tạo khoảng cách phía dưới tiêu đề */
}

.content-product-container {
  display: flex;
  justify-content: center; /* Căn giữa nội dung sản phẩm */
  flex-direction: column;
  align-items: center;
}

.content-product-container__product-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Khoảng cách giữa các sản phẩm */
  justify-content: center; /* Căn giữa các thẻ sản phẩm */
}

/* CSS cho các thẻ sản phẩm */
.content-product-all-wrapper__list-product {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Khoảng cách giữa các sản phẩm */
  justify-content: center;
}

.content-product-all-wrapper__product-item {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 1px solid #eee; /* Border nhẹ cho thẻ sản phẩm */
  border-radius: 8px;
  transition: transform 0.3s ease;
  max-width: 220px; /* Giới hạn chiều rộng thẻ sản phẩm */

  &:hover {
    transform: scale(1.05); /* Phóng to thẻ sản phẩm khi hover */
  }

 

  p {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    text-align: center;
    text-transform: uppercase;
    line-height: 24px;
    transition: color 0.3s ease;
  }
}
@media (max-width: 550px) {
  /* Giảm padding cho khung trang */
  .content-product-all-wrapper {
    padding: 10px;
  }

  /* Căn giữa breadcrumb và tiêu đề */
  .content-product-all-container__breadcrumb,
  .content-product-all-container__description {
    text-align: center;
    margin-bottom: 15px;
  }

  /* Sắp xếp lại các thẻ sản phẩm trên một cột */
  .content-product-container__product-content {
    display: flex;
    flex-direction: column; /* Xếp thẻ sản phẩm theo cột */
    gap: 15px; /* Khoảng cách giữa các thẻ */
    align-items: center;
  }

  /* Định dạng lại các thẻ sản phẩm cho kích thước nhỏ */
  .content-product-all-wrapper__product-item {
    width: 100%; /* Chiếm hết chiều ngang màn hình */
    max-width: 100%; /* Bỏ giới hạn chiều rộng để mở rộng trên màn hình nhỏ */
    padding: 10px;
  }

  /* Đặt lại kích thước hình ảnh cho màn hình nhỏ */
  .content-product-all-wrapper__product-item img {
    max-width: 100%;
    height: auto; /* Điều chỉnh chiều cao tự động theo chiều rộng */
  }

  /* Thiết lập lại font-size cho mobile */
  .content-product-all-wrapper__product-item p {
    font-size: 12px;
    line-height: 20px;
  }
}