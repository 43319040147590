.list-product {
  display: flex;
  gap: 10px;

  &__filter {
    line-height: 2;
    padding: 40px 15px;
    max-width: 350px;
    height: 100%;
    text-align: left;

    &-category, &-price, &-star {
      h4 {
        margin-bottom: 8px;
      }
    }

    &-btn {
      width: 100px;
      height: 30px;
      box-sizing: border-box;
      border: 1px solid black;
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        background-color: gainsboro;
      }
    }

    &-grp {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
    }
  }

  &__content {
    position: relative;
    min-height: 1px;
    padding: 0 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-search {
      align-self: center;
      width: 500px;
      display: flex;
      height: 50px;
      align-items: center;
      gap: 5px;

      &-btn {
        background-color: #15a69d;
      }
    }

    &-filter {
      align-self: flex-end;
    }

    &-items {
      &__row {
        gap: 10px;
      }
    }

    &-pagination {
      display: flex;
      justify-content: center;
    }
  }
}

// Media Queries for Responsive Design
@media screen and (max-width: 900px) {
  .list-product {
    flex-direction: column;

    &__filter {
      padding: 8px;

      .list-product__filter-btn {
        margin-top: 16px;
        padding: 8px;
      }

      &-category, &-price, &-star {
        padding: 8px;
      }
    }

    &__content {
      &-filter {
        flex-direction: column;
        gap: 10px;

        .ant-select {
          width: 100%;
        }
      }

      &-items {
        .ant-col {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }

 
}

@media screen and (max-width: 600px) {
  .list-product {
    flex-direction: column;
    gap: 20px;
    align-items: center;

    &__filter {
      width: 100%;
      padding: 15px;
      justify-items: center;
    }

    &__content {
      width: 100%;
    }

    &__search {
      flex-direction: column;
      gap: 10px;

      .input-search,
      .list-product__search-btn {
        width: 100%;
      }
    }
.respon-h32 {
    height: 32px;
  }
    &__content-filter {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }

    &__content-items {
      gap: 15px;

      .ant-col {
        width: 100%;
      }
    }
  }
}

// Filter Toggle Button Styles
.list-product__filter-toggle {
  display: none; // Hide on desktop
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  background-color: #15a69d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center; // Center icon
  align-items: center; // Center icon

  &:hover {
    background-color: #13a68b;
  }
}

// Show toggle button on mobile
@media screen and (max-width: 900px) {
  .list-product__filter-toggle {
    display: flex;
  }
}
