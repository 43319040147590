* {
  box-sizing: border-box;
}

.content-normal-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: auto;
  width: 100vw;
  //   max-width: 2000px;
      text-align: -webkit-center;

}
